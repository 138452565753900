@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.boot-camp-details {
  $breakpoint: 500px;
  $breakpointLarge: 800px;
  margin-bottom: spacing.$xl;

  &__header {
    background-color: light.$background-secondary-default;
    padding: spacing.$l 0;
    border-bottom: 1px solid light.$ge-divider-default;
  }

  &__header-inner {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
  }

  &__header-details {
    display: flex;
    flex-wrap: wrap;
    row-gap: spacing.$m;
    column-gap: spacing.$xs;
  }

  &__header-top {
    display: flex;
    justify-content: space-between;
  }

  &__link {
    display: flex;
  }

  &__context-menu-wrpaper {
    position: relative;
  }

  &__tags {
    display: flex;
    gap: spacing.$s;
  }

  &__header-icon-text {
    display: flex;
    gap: spacing.$xxs;
    align-items: center;
  }

  &__description {
    margin-top: spacing.$m;
    border-radius: corner-radius.$m;
    padding: spacing.$m;
    padding-bottom: spacing.$xxs;
    background-color: light.$background-secondary-default;

    &--bottom-padding {
      padding-bottom: spacing.$m;
    }

    @media(min-width: $breakpoint) {
      padding-bottom: spacing.$m;
    }
  }

  &__description-text-wrapper {
    padding-top: spacing.$m;
  }

  &__description-ellipsis {
    @media(min-width: $breakpoint) {
      display: none;
    }
  }

  &__description-text {
    display: none;

    @media(min-width: $breakpoint) {
      display: inline;
    }

    &--show {
      display: inline;
    }
  }

  &__description-show-more-button {
    @media(min-width: $breakpoint) {
      display: none;
    }
  }

  &__info {
    margin-top: spacing.$m;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: spacing.$m;

    @media(max-width: $breakpointLarge) {
      flex-direction: column;
    }

    > * {
      flex: 1;
      @media(min-width: $breakpointLarge) {
        align-self: flex-start;
      }
    }
  }

  &__cancel-button {
    background: light.$surface-primary-default;
    border-radius: corner-radius.$s;
    margin-top: spacing.$m;
  }

  &__meta {
    display: flex;
    gap: spacing.$xxs;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: spacing.$m;
    color: light.$on-surface-primary-alternate;

    > * {
      display: flex;
      gap: spacing.$xxs;
      flex-shrink: 0;
    }

    @media (max-width: $breakpoint) {
      flex-direction: column;
    }
  }

  &__meta-splitter {
    @media (max-width: $breakpoint) {
      display: none;
    }
  }
}
